import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import Covid from '../../images/vids/Covid'

import { Container as Containr, CenterContainer, mediaQuery } from './styled'

const Container = styled(Containr)`
  flex: 1;
  margin-bottom: 2.5rem;
  position: relative;
  ${mediaQuery.mobile} {
    margin-bottom: 1rem;
  }
`
const BlackBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0
  left: 0;
  width: 100%;
  height: calc(3rem + 8rem + 12rem);
  z-index: -1;
  ${mediaQuery.mobile} {
    height: calc(1.75rem + 3rem + 25vw);
  }
`
const SpacerRedLineContainer = styled.div`
  flex: 1;
  position: relative;
`
const SpacerRedLineLeft = styled.div`
  position: absolute;
  top: calc(3rem + 4rem + 1rem);
  left: 0;
  width: calc(((100vw - 37.5rem) / 2) + (5rem));
  height: 2px;
  background-color: ${({ theme }) => theme.colors.red};
  ${mediaQuery.tabletLandscape} {
    width: calc(5vw + 6rem);
  }
  ${mediaQuery.tablet} {
    width: calc(5vw + 3rem);
  }
  ${mediaQuery.mobile} {
    width: calc(5vw + 2rem);
    top: calc(1.5rem + 1.75rem + 0.75rem);
  }
  ${mediaQuery.helper400} {
    width: calc(5vw + 1.5rem);
    top: calc(1.5rem + 1.75rem + 0.75rem);
  }
  ${mediaQuery.helper350} {
    width: calc(5vw + 1rem);
    top: calc(1.5rem + 1.75rem + 0.75rem);
  }
`
const SpacerRedLineRight = styled.div`
  position: absolute;
  top: 3rem;
  right: 0;
  width: calc(((100vw - 37.5rem) / 2) + (5rem));
  height: 2px;
  background-color: ${({ theme }) => theme.colors.red};
  ${mediaQuery.tabletLandscape} {
    width: calc(5vw + 6rem);
  }
  ${mediaQuery.tablet} {
    width: calc(5vw + 3rem);
  }
  ${mediaQuery.mobile} {
    width: calc(5vw + 2rem);
    top: 0.75rem;
  }
  ${mediaQuery.helper400} {
    width: calc(5vw + 1.5rem);
    top: 0.75rem;
  }
  ${mediaQuery.helper350} {
    width: calc(5vw + 1rem);
    top: 0.75rem;
  }
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.headline2};
  color: ${({ theme }) => theme.colors.white};
  margin: 4rem auto;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline2_mobile};
    margin: 1.5rem auto;
  }
`
const CardContainer = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
`
const TextContainerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
`
const TextContainer = styled.div`
  width: 12rem;
  margin: auto auto 3rem 3rem;
  z-index: 1;
  ${mediaQuery.mobile} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
`
const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.headline3};
  color: ${({ theme }) => theme.colors.white};
  width: 12rem;
  line-height: 1;
  margin-bottom: 1rem;
  ${mediaQuery.tabletLandscape} {
    ${({ theme }) => theme.fonts.headline3};
    width: calc(70% - 1rem);
  }
  ${mediaQuery.tablet} {
    ${({ theme }) => theme.fonts.headline3};
    width: calc(70% - 1rem);
  }
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline3_mobile};
    width: calc(70% - 1rem);
    margin: auto auto 1rem 1rem;
  }
`
const CardAction = styled.div`
  ${({ theme }) => theme.fonts.cardAction};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${mediaQuery.mobile} {
    display: none;
  }
`
const PlayButtonContainer = styled.div`
  position: absolute;
  top: calc(50% - (4.6875rem / 2));
  left: calc(50% - (4.6875rem / 2));
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
  background-color: rgba(249, 249, 249, 0.39);
  z-index: 1;
  display: flex;
  ${mediaQuery.tabletLandscape} {
    transform: scale(0.7);
  }
  ${mediaQuery.tablet} {
    transform: scale(0.5);
  }
  ${mediaQuery.mobile} {
    transform: scale(0.5);
  }
`
const PlayButtonWrapper = styled.div`
  transform: rotate(90deg);
  margin-left: 0.3rem;
`

const PlayButton = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.red};
  text-align: left;
  width: 1.25em;
  height: 1.25em;
  border-top-right-radius: 30%;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

  :before,
  :after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 1.25em;
    height: 1.25em;
    border-top-right-radius: 30%;
  }

  :before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  :after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`
const CardImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const StyledImage = styled(Covid)`
  width: 100%;
  height: 100%;
`
const Description = styled.div`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  color: ${({ theme }) => theme.colors.black};
  margin: 1rem 0 0 auto;
  text-align: right;
  ${mediaQuery.mobile} {
    display: none;
  }
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${({ showIframe }) => {
    return showIframe
      ? { opacity: 1, pointerEvents: 'auto' }
      : { opacity: 0, pointerEvents: 'none' }
  }};
  transition: all 2s;
  z-index: 20;
`

const Iframe = styled.iframe`
  height: calc(100% + 1px);
  width: 100%;
`
const Latest = ({ theme, text }) => {
  const [showIframe, setShowIframe] = useState(false)

  const latestEpisode = useStaticQuery(graphql`
    query TutorialQuery {
      tutorials: allAirtable(
        filter: { table: { eq: "tutorials" }, data: { active: { eq: true } } }
        sort: { fields: data___order, order: ASC }
      ) {
        nodes {
          data {
            title
            action
            link
          }
          recordId
        }
      }
    }
  `).tutorials.nodes[0].data

  return (
    <Container>
      <BlackBackground />
      <SpacerRedLineContainer>
        <SpacerRedLineLeft />
      </SpacerRedLineContainer>
      <CenterContainer>
        <Title>{text.title}</Title>
        <CardContainer onClick={() => setShowIframe(true)}>
          <TextContainerContainer>
            <TextContainer>
              <CardTitle>{latestEpisode.title}</CardTitle>
              <CardAction>{latestEpisode.action}</CardAction>
            </TextContainer>
          </TextContainerContainer>
          <PlayButtonContainer>
            <PlayButtonWrapper>
              <PlayButton></PlayButton>
            </PlayButtonWrapper>
          </PlayButtonContainer>
          <CardImageContainer showIframe={showIframe}>
            <StyledImage />
          </CardImageContainer>
          <IframeContainer showIframe={showIframe}>
            <Iframe
              src={latestEpisode.link}
              title={latestEpisode.title}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
            />
          </IframeContainer>
        </CardContainer>
        <Description>{text.body}</Description>
      </CenterContainer>
      <SpacerRedLineContainer>
        <SpacerRedLineRight />
      </SpacerRedLineContainer>
    </Container>
  )
}

Latest.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Latest)
